import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  List,
  ListItemText,
  Avatar,
  TextField,
  Button,
  InputLabel,
  useTheme,
  Divider,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import IconSettings from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import { banksList, getBusinessProfile } from "../../helpers/backend_helper"; 
import { updateBusinessProfile } from "../../helpers/backend_helper"; // Import the function

const Settings = () => {
  const [selectedMenu, setSelectedMenu] = useState("Business Profile");
  const [businessName, setBusinessName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [settlementAccountNumber, setSettlementAccountNumber] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [editMode, setEditMode] = useState({
    email: false,
    phone: false,
    settlementAccountNumber: false,
  });
  const theme = useTheme();
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await banksList();
        if (Array.isArray(response.data)) {
          setBankList(response.data);
        } else {
          console.error("Fetched banks data is not an array:", response);
        }
      } catch (error) {
        console.error("Failed to fetch banks:", error);
      }
    };

    fetchBanks();
  }, []);

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };

  useEffect(() => {
    const fetchBusinessProfile = async () => {
      try {
        const response = await getBusinessProfile();
        const data = response.data; 
        if (data) {
          const businessPartner = data.businessPartner;
          setBusinessName(businessPartner.companyName);
  
          const planMapping = {
            "Silver_Basic": "Silver",
            "Gold_Basic": "Gold",
            "Platinum_Basic": "Platinum",
          };
  
          setSubscriptionPlan(planMapping[businessPartner.plan] || businessPartner.plan);
          setEmail(data.buisnessContactEmail || "");
          setPhone(data.buisnessPhoneNumber || "");
          if (selectedMenu === "Settlement Account") {
            setSettlementAccountNumber(data.settlementAccountNumber || "");
            const settlementBankId = Number(data.settlementBankId);
            const selectedBank = bankList.find(
              (bank) => bank.id === settlementBankId
            );
            setSelectedBank(selectedBank?.id || "");
          }
        }
      } catch (error) {
        console.error(`Error fetching ${selectedMenu} data:`, error);
      }
    };
  
    fetchBusinessProfile();
  }, [selectedMenu, bankList]);

  const listItemStyles = (menuName) => ({
    backgroundColor: selectedMenu === menuName ? "#ffe6d5" : "transparent",
    borderRadius: theme.shape.borderRadius,
    mb: 1,
    "&:hover": {
      backgroundColor: "#ffe6d5",
    },
  });

  const listItemTextStyles = (menuName) => ({
    color: selectedMenu === menuName ? "#FC5719" : theme.palette.text.primary,
    textAlign: "center",
  });
  const renderFormField = (label, value, setValue, fieldKey, options = {}) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        mt: 3,
      }}
    >
      <InputLabel sx={{ mb: 1, fontWeight: 500, fontSize: "medium" , color: 'black' }}>
        {label}
      </InputLabel>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        variant="outlined"
        fullWidth
        sx={{ backgroundColor: theme.palette.background.paper }}
        disabled={!editMode[fieldKey]}
        error={options.error || false}
        helperText={options.helperText || ""}
        placeholder={options.placeholder || ""}
        InputProps={{
          endAdornment: !options.disabled && (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    [fieldKey]: !prev[fieldKey],
                  }))
                }
                sx={{ color: theme.palette.primary.secondary }}
              >
                <EditIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );

  const handleSaveProfile = () => {
    if (!email.includes("@")) {
      toast.error("Please enter a valid email address.");
      return;
    }
    if (!/^\d+$/.test(phone)) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    const requestData = {
      buisnessContactEmail: email,
      buisnessPhoneNumber: phone,
    };

    updateBusinessProfile(requestData)
      .then((response) => {
        toast.success("Profile updated successfully.");
        setEditMode({ email: false, phone: false });
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        toast.error("Failed to update profile. Please try again.");
      });
  };

  const handleSaveSettlementAccount = () => {
    const requestData = {
      settlementAccountNumber: settlementAccountNumber,
      settlementBankId: selectedBank,
      settlementBankName: bankList.find((bank) => bank.id === selectedBank)
        ?.displayName,
    };

    updateBusinessProfile(requestData)
      .then((response) => {
        toast.success("Settlement Account updated successfully.");
        setEditMode({ settlementAccountNumber: false });
      })
      .catch((error) => {
        console.error("Error updating Settlement account:", error);
        toast.error("Failed to update Settlement account. Please try again.");
      });
  };

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("profilePicInput").click();
  };

  return (
    <Box sx={{ p: 4, mt: 10 }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconSettings fontSize="medium" color="action" />
        <Typography variant="Body_light_20" fontWeight={600}>
          Settings
        </Typography>
      </Stack>
      <Grid container spacing={4} mt={5}>
        <Grid item xs={12} md={3}>
          <Box sx={{ width: "100%", maxWidth: "260px" }}>
            <List component="nav" sx={{ p: 0 }}>
              <ListItemButton
                onClick={() => setSelectedMenu("Business Profile")}
                sx={listItemStyles("Business Profile")}
              >
                <ListItemText
                  primary="Business Profile"
                  sx={listItemTextStyles("Business Profile")}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                onClick={() => setSelectedMenu("Settlement Account")}
                sx={listItemStyles("Settlement Account")}
              >
                <ListItemText
                  primary="Settlement Account"
                  sx={listItemTextStyles("Settlement Account")}
                />
              </ListItemButton>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              p: 4,
              backgroundColor: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius,
              boxShadow: theme.shadows[1],
              maxWidth: "500px",
            }}
          >
            {selectedMenu === "Business Profile" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ position: "relative", display: "inline-block" }}>
                  <Avatar
                    alt="User Profile Picture"
                    src={profilePic}
                    sx={{ width: 120, height: 120, mb: 3 }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      bottom: 30,
                      right: 5,
                      width: 24,
                      height: 24,
                      backgroundColor: "white",
                      border: "1px solid ",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    onClick={triggerFileInput}
                  >
                    <EditIcon sx={{ width: 18 }} />
                  </IconButton>
                </Box>
                <input
                  accept="image/*"
                  type="file"
                  id="profilePicInput"
                  onChange={handleProfilePicChange}
                  style={{ display: "none" }}
                />
                {renderFormField(
                  "Business Name",
                  businessName,
                  setBusinessName,
                  "businessName",
                  {
                    disabled: true,
                  }
                )}
                {renderFormField(
                  "Subscription Plan",
                  subscriptionPlan,
                  setSubscriptionPlan,
                  "subscriptionPlan",
                  {
                    disabled: true,
                  }
                )}
                {renderFormField(
                  "Business Contact Email",
                  email,
                  setEmail,
                  "email",
                  {
                    placeholder: "Enter your business Contact email",
                  }
                )}
                {renderFormField(
                  "Business Phone Number",
                  phone,
                  setPhone,
                  "phone",
                  {
                    placeholder: "Enter your business phone number",
                  }
                )}
                <Box sx={{ mt: 4, width: "100%" }}>
                  <Button
                    disableElevation
                    fullWidth
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveProfile}
                  >
                    Save Profile
                  </Button>
                </Box>
              </Box>
            )}
            {selectedMenu === "Settlement Account" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ mt: 4, width: "100%" }}>
                  <InputLabel
                    sx={{ mb: 1, fontWeight: 500, fontSize: "medium" , color: 'black' }}
                  >
                    Bank Name
                  </InputLabel>
                  <Select
                    value={selectedBank || ""}
                    onChange={handleBankChange}
                    displayEmpty
                    fullWidth
                    renderValue={(selected) => {
                      if (selected === "") {
                        return (
                          <span style={{ color: "#aaa", fontWeight: "light" }}>
                            Select your bank
                          </span>
                        );
                      }
                      const selectedBankObj = bankList.find(
                        (bank) => bank.id === selected
                      );
                      return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={selectedBankObj.icon}
                            alt={selectedBankObj.displayName}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          />
                          {selectedBankObj.displayName}
                        </Box>
                      );
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select your bank
                    </MenuItem>
                    {bankList.map((bank) => (
                      <MenuItem key={bank.id} value={bank.id}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ListItemIcon>
                            <img
                              src={bank.icon}
                              alt={bank.displayName}
                              style={{ width: 24, height: 24 }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={bank.displayName} />
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                {renderFormField(
                  "Settlement Account",
                  settlementAccountNumber,
                  setSettlementAccountNumber,
                  "settlementAccountNumber",
                  {
                    placeholder: "Enter your settlement account number",
                  }
                )}
                <Box sx={{ mt: 4, width: "100%" }}>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveSettlementAccount}
                  >
                    Add Account
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;